/* Generated by restful-react */

import React from "react";
import { Get, GetProps, useGet, UseGetProps } from "restful-react";
export const SPEC_VERSION = "0.2.0";
export interface Event {
  id: number;
  /**
   * Event ID used at source
   */
  sourceEventId: string;
  /**
   * Event details URL at source
   */
  sourceUrl?: string | null;
  /**
   * License under which source published event data
   */
  sourceLicense?: string | null;
  /**
   * Event title
   */
  name: string;
  /**
   * Plain text event description and details
   */
  description?: string | null;
  /**
   * HTML event description and details (if available from source, otherwise matches plain text description). Will only contain these HTML tags: b, br, em, i, li, p, span, strong, ul.
   */
  formattedDescription?: string | null;
  /**
   * Event website
   */
  url?: string | null;
  /**
   * Start date as YYYY-MM-DD
   */
  startDate: string;
  /**
   * Start time as HH:MM:SS (optional)
   */
  startTime?: string | null;
  /**
   * Ende date as YYYY-MM-DD (optional)
   */
  endDate?: string | null;
  /**
   * End time as HH:MM:SS (optional)
   */
  endTime?: string | null;
  /**
   * Main event performer (e.g. speaker, band, etc.)
   */
  performer?: string | null;
  /**
   * Event mode (e.g. presentation, meetup, etc.)
   */
  mode?: string | null;
  source: {
    id: number;
    /**
     * Name of event source
     */
    name: string;
  };
  location: {
    id: number;
    /**
     * Description of event location (address or similar)
     */
    description: string;
    /**
     * Latitude of the event location
     */
    lat?: number | null;
    /**
     * Longitude of the event location
     */
    lon?: number | null;
    /**
     * Source of the geometry in lat and lon fields
     */
    geometrySource?: string | null;
  };
  organizer: {
    id: number;
    /**
     * Name of event organizer
     */
    name: string;
  };
  images: {
    id: number;
    url: string;
    description?: string | null;
    source?: string | null;
    event: number;
  }[];
}

export interface EventSource {
  id: number;
  /**
   * Name of event source
   */
  name: string;
}

export interface Location {
  id: number;
  /**
   * Description of event location (address or similar)
   */
  description: string;
  /**
   * Latitude of the event location
   */
  lat?: number | null;
  /**
   * Longitude of the event location
   */
  lon?: number | null;
  /**
   * Source of the geometry in lat and lon fields
   */
  geometrySource?: string | null;
}

export interface Organizer {
  id: number;
  /**
   * Name of event organizer
   */
  name: string;
}

export type ListEventsResponse = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Event[];
};

export interface ListEventsQueryParams {
  /**
   * A page number within the paginated result set.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  limit?: number;
  /**
   * Location ID to filter events for
   */
  location?: string;
  /**
   * Organizer ID to filter events for
   */
  organizer?: string;
  /**
   * Earliest event start date
   */
  minDate?: string;
  /**
   * Latest event start date
   */
  maxDate?: string;
}

export type ListEventsProps = Omit<
  GetProps<
    ListEventsResponse,
    | {
        page?: string[];
        limit?: string[];
        location?: string[];
        organizer?: string[];
        minDate?: string[];
        maxDate?: string[];
      }
    | {
        detail: string;
      },
    ListEventsQueryParams,
    void
  >,
  "path"
>;

/**
 * Anfragen an die `/events` Schnittstelle geben die aktuell in der Datenbank
 * verfügbaren Veranstaltungen zurück.
 *
 * Über die Parameter `minDate` und `maxDate` lässt sich die Rückgabemenge
 * bezüglich des Anfangs der Veranstaltungen beschränken.
 * Datumsformat hier ist `JJJJ-MM-DD` (ISO 8601).
 *
 * Mittels Parametern `location` und `organizer` lassen sich Veranstaltungen
 * für bestimmte [Orte](/locations) und oder [Veranstalter](/organizers)
 * filtern. Beide Parameter
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const ListEvents = (props: ListEventsProps) => (
  <Get<
    ListEventsResponse,
    | {
        page?: string[];
        limit?: string[];
        location?: string[];
        organizer?: string[];
        minDate?: string[];
        maxDate?: string[];
      }
    | {
        detail: string;
      },
    ListEventsQueryParams,
    void
  >
    path={`/events`}
    {...props}
  />
);

export type UseListEventsProps = Omit<
  UseGetProps<
    ListEventsResponse,
    | {
        page?: string[];
        limit?: string[];
        location?: string[];
        organizer?: string[];
        minDate?: string[];
        maxDate?: string[];
      }
    | {
        detail: string;
      },
    ListEventsQueryParams,
    void
  >,
  "path"
>;

/**
 * Anfragen an die `/events` Schnittstelle geben die aktuell in der Datenbank
 * verfügbaren Veranstaltungen zurück.
 *
 * Über die Parameter `minDate` und `maxDate` lässt sich die Rückgabemenge
 * bezüglich des Anfangs der Veranstaltungen beschränken.
 * Datumsformat hier ist `JJJJ-MM-DD` (ISO 8601).
 *
 * Mittels Parametern `location` und `organizer` lassen sich Veranstaltungen
 * für bestimmte [Orte](/locations) und oder [Veranstalter](/organizers)
 * filtern. Beide Parameter
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const useListEvents = (props: UseListEventsProps) =>
  useGet<
    ListEventsResponse,
    | {
        page?: string[];
        limit?: string[];
        location?: string[];
        organizer?: string[];
        minDate?: string[];
        maxDate?: string[];
      }
    | {
        detail: string;
      },
    ListEventsQueryParams,
    void
  >(`/events`, props);

export interface RetrieveEventQueryParams {
  /**
   * Location ID to filter events for
   */
  location?: string;
  /**
   * Organizer ID to filter events for
   */
  organizer?: string;
  /**
   * Earliest event start date
   */
  minDate?: string;
  /**
   * Latest event start date
   */
  maxDate?: string;
}

export interface RetrieveEventPathParams {
  /**
   * A unique integer value identifying this event.
   */
  id: string;
}

export type RetrieveEventProps = Omit<
  GetProps<
    Event,
    | {
        id?: string[];
        location?: string[];
        organizer?: string[];
        minDate?: string[];
        maxDate?: string[];
      }
    | {
        detail: string;
      },
    RetrieveEventQueryParams,
    RetrieveEventPathParams
  >,
  "path"
> &
  RetrieveEventPathParams;

/**
 * Anfragen an die `/events` Schnittstelle geben die aktuell in der Datenbank
 * verfügbaren Veranstaltungen zurück.
 *
 * Über die Parameter `minDate` und `maxDate` lässt sich die Rückgabemenge
 * bezüglich des Anfangs der Veranstaltungen beschränken.
 * Datumsformat hier ist `JJJJ-MM-DD` (ISO 8601).
 *
 * Mittels Parametern `location` und `organizer` lassen sich Veranstaltungen
 * für bestimmte [Orte](/locations) und oder [Veranstalter](/organizers)
 * filtern. Beide Parameter
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const RetrieveEvent = ({ id, ...props }: RetrieveEventProps) => (
  <Get<
    Event,
    | {
        id?: string[];
        location?: string[];
        organizer?: string[];
        minDate?: string[];
        maxDate?: string[];
      }
    | {
        detail: string;
      },
    RetrieveEventQueryParams,
    RetrieveEventPathParams
  >
    path={`/events/${id}`}
    {...props}
  />
);

export type UseRetrieveEventProps = Omit<
  UseGetProps<
    Event,
    | {
        id?: string[];
        location?: string[];
        organizer?: string[];
        minDate?: string[];
        maxDate?: string[];
      }
    | {
        detail: string;
      },
    RetrieveEventQueryParams,
    RetrieveEventPathParams
  >,
  "path"
> &
  RetrieveEventPathParams;

/**
 * Anfragen an die `/events` Schnittstelle geben die aktuell in der Datenbank
 * verfügbaren Veranstaltungen zurück.
 *
 * Über die Parameter `minDate` und `maxDate` lässt sich die Rückgabemenge
 * bezüglich des Anfangs der Veranstaltungen beschränken.
 * Datumsformat hier ist `JJJJ-MM-DD` (ISO 8601).
 *
 * Mittels Parametern `location` und `organizer` lassen sich Veranstaltungen
 * für bestimmte [Orte](/locations) und oder [Veranstalter](/organizers)
 * filtern. Beide Parameter
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const useRetrieveEvent = ({ id, ...props }: UseRetrieveEventProps) =>
  useGet<
    Event,
    | {
        id?: string[];
        location?: string[];
        organizer?: string[];
        minDate?: string[];
        maxDate?: string[];
      }
    | {
        detail: string;
      },
    RetrieveEventQueryParams,
    RetrieveEventPathParams
  >((paramsInPath: RetrieveEventPathParams) => `/events/${paramsInPath.id}`, {
    pathParams: { id },
    ...props,
  });

export type ListEventSourcesResponse = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: EventSource[];
};

export interface ListEventSourcesQueryParams {
  /**
   * A page number within the paginated result set.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  limit?: number;
}

export type ListEventSourcesProps = Omit<
  GetProps<
    ListEventSourcesResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListEventSourcesQueryParams,
    void
  >,
  "path"
>;

/**
 * Anfragen an die `/sources` Schnittstelle geben die aktuell in der Datenbank
 * verfügbaren Quellen von Veranstaltungen zurück.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const ListEventSources = (props: ListEventSourcesProps) => (
  <Get<
    ListEventSourcesResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListEventSourcesQueryParams,
    void
  >
    path={`/sources`}
    {...props}
  />
);

export type UseListEventSourcesProps = Omit<
  UseGetProps<
    ListEventSourcesResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListEventSourcesQueryParams,
    void
  >,
  "path"
>;

/**
 * Anfragen an die `/sources` Schnittstelle geben die aktuell in der Datenbank
 * verfügbaren Quellen von Veranstaltungen zurück.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const useListEventSources = (props: UseListEventSourcesProps) =>
  useGet<
    ListEventSourcesResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListEventSourcesQueryParams,
    void
  >(`/sources`, props);

export interface RetrieveEventSourcePathParams {
  /**
   * A unique integer value identifying this event source.
   */
  id: string;
}

export type RetrieveEventSourceProps = Omit<
  GetProps<
    EventSource,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveEventSourcePathParams
  >,
  "path"
> &
  RetrieveEventSourcePathParams;

/**
 * Anfragen an die `/sources` Schnittstelle geben die aktuell in der Datenbank
 * verfügbaren Quellen von Veranstaltungen zurück.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const RetrieveEventSource = ({
  id,
  ...props
}: RetrieveEventSourceProps) => (
  <Get<
    EventSource,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveEventSourcePathParams
  >
    path={`/sources/${id}`}
    {...props}
  />
);

export type UseRetrieveEventSourceProps = Omit<
  UseGetProps<
    EventSource,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveEventSourcePathParams
  >,
  "path"
> &
  RetrieveEventSourcePathParams;

/**
 * Anfragen an die `/sources` Schnittstelle geben die aktuell in der Datenbank
 * verfügbaren Quellen von Veranstaltungen zurück.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const useRetrieveEventSource = ({
  id,
  ...props
}: UseRetrieveEventSourceProps) =>
  useGet<
    EventSource,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveEventSourcePathParams
  >(
    (paramsInPath: RetrieveEventSourcePathParams) =>
      `/sources/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export type ListLocationsResponse = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Location[];
};

export interface ListLocationsQueryParams {
  /**
   * A page number within the paginated result set.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  limit?: number;
}

export type ListLocationsProps = Omit<
  GetProps<
    ListLocationsResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListLocationsQueryParams,
    void
  >,
  "path"
>;

/**
 * Anfragen an die `/locations` Schnittstelle geben die aktuell in der
 * Datenbank verfügbaren Orte von Veranstaltungen zurück.
 *
 * Die im Feld `id` enthaltene ID eines Ortes kann als `location` Parameter
 * in der [`/events`](/events) Schnittstelle verwendet werden.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const ListLocations = (props: ListLocationsProps) => (
  <Get<
    ListLocationsResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListLocationsQueryParams,
    void
  >
    path={`/locations`}
    {...props}
  />
);

export type UseListLocationsProps = Omit<
  UseGetProps<
    ListLocationsResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListLocationsQueryParams,
    void
  >,
  "path"
>;

/**
 * Anfragen an die `/locations` Schnittstelle geben die aktuell in der
 * Datenbank verfügbaren Orte von Veranstaltungen zurück.
 *
 * Die im Feld `id` enthaltene ID eines Ortes kann als `location` Parameter
 * in der [`/events`](/events) Schnittstelle verwendet werden.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const useListLocations = (props: UseListLocationsProps) =>
  useGet<
    ListLocationsResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListLocationsQueryParams,
    void
  >(`/locations`, props);

export interface RetrieveLocationPathParams {
  /**
   * A unique integer value identifying this location.
   */
  id: string;
}

export type RetrieveLocationProps = Omit<
  GetProps<
    Location,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveLocationPathParams
  >,
  "path"
> &
  RetrieveLocationPathParams;

/**
 * Anfragen an die `/locations` Schnittstelle geben die aktuell in der
 * Datenbank verfügbaren Orte von Veranstaltungen zurück.
 *
 * Die im Feld `id` enthaltene ID eines Ortes kann als `location` Parameter
 * in der [`/events`](/events) Schnittstelle verwendet werden.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const RetrieveLocation = ({ id, ...props }: RetrieveLocationProps) => (
  <Get<
    Location,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveLocationPathParams
  >
    path={`/locations/${id}`}
    {...props}
  />
);

export type UseRetrieveLocationProps = Omit<
  UseGetProps<
    Location,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveLocationPathParams
  >,
  "path"
> &
  RetrieveLocationPathParams;

/**
 * Anfragen an die `/locations` Schnittstelle geben die aktuell in der
 * Datenbank verfügbaren Orte von Veranstaltungen zurück.
 *
 * Die im Feld `id` enthaltene ID eines Ortes kann als `location` Parameter
 * in der [`/events`](/events) Schnittstelle verwendet werden.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const useRetrieveLocation = ({
  id,
  ...props
}: UseRetrieveLocationProps) =>
  useGet<
    Location,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveLocationPathParams
  >(
    (paramsInPath: RetrieveLocationPathParams) =>
      `/locations/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );

export type ListOrganizersResponse = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Organizer[];
};

export interface ListOrganizersQueryParams {
  /**
   * A page number within the paginated result set.
   */
  page?: number;
  /**
   * Number of results to return per page.
   */
  limit?: number;
}

export type ListOrganizersProps = Omit<
  GetProps<
    ListOrganizersResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListOrganizersQueryParams,
    void
  >,
  "path"
>;

/**
 * Anfragen an die `/organizers` Schnittstelle geben die aktuell in der
 * Datenbank verfügbaren Veranstalter von Veranstaltungen zurück.
 *
 * Die im Feld `id` enthaltene ID eines Veranstalters kann als `organizer`
 * Parameter in der [`/events`](/events) Schnittstelle verwendet werden.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const ListOrganizers = (props: ListOrganizersProps) => (
  <Get<
    ListOrganizersResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListOrganizersQueryParams,
    void
  >
    path={`/organizers`}
    {...props}
  />
);

export type UseListOrganizersProps = Omit<
  UseGetProps<
    ListOrganizersResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListOrganizersQueryParams,
    void
  >,
  "path"
>;

/**
 * Anfragen an die `/organizers` Schnittstelle geben die aktuell in der
 * Datenbank verfügbaren Veranstalter von Veranstaltungen zurück.
 *
 * Die im Feld `id` enthaltene ID eines Veranstalters kann als `organizer`
 * Parameter in der [`/events`](/events) Schnittstelle verwendet werden.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const useListOrganizers = (props: UseListOrganizersProps) =>
  useGet<
    ListOrganizersResponse,
    | {
        page?: string[];
        limit?: string[];
      }
    | {
        detail: string;
      },
    ListOrganizersQueryParams,
    void
  >(`/organizers`, props);

export interface RetrieveOrganizerPathParams {
  /**
   * A unique integer value identifying this organizer.
   */
  id: string;
}

export type RetrieveOrganizerProps = Omit<
  GetProps<
    Organizer,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveOrganizerPathParams
  >,
  "path"
> &
  RetrieveOrganizerPathParams;

/**
 * Anfragen an die `/organizers` Schnittstelle geben die aktuell in der
 * Datenbank verfügbaren Veranstalter von Veranstaltungen zurück.
 *
 * Die im Feld `id` enthaltene ID eines Veranstalters kann als `organizer`
 * Parameter in der [`/events`](/events) Schnittstelle verwendet werden.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const RetrieveOrganizer = ({ id, ...props }: RetrieveOrganizerProps) => (
  <Get<
    Organizer,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveOrganizerPathParams
  >
    path={`/organizers/${id}`}
    {...props}
  />
);

export type UseRetrieveOrganizerProps = Omit<
  UseGetProps<
    Organizer,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveOrganizerPathParams
  >,
  "path"
> &
  RetrieveOrganizerPathParams;

/**
 * Anfragen an die `/organizers` Schnittstelle geben die aktuell in der
 * Datenbank verfügbaren Veranstalter von Veranstaltungen zurück.
 *
 * Die im Feld `id` enthaltene ID eines Veranstalters kann als `organizer`
 * Parameter in der [`/events`](/events) Schnittstelle verwendet werden.
 *
 * Weitere Informationen zum Rückgabeschema und Parametern im
 * [Swagger UI](/docs/) oder [ReDoc](/redoc/).
 */
export const useRetrieveOrganizer = ({
  id,
  ...props
}: UseRetrieveOrganizerProps) =>
  useGet<
    Organizer,
    | {
        id?: string[];
      }
    | {
        detail: string;
      },
    void,
    RetrieveOrganizerPathParams
  >(
    (paramsInPath: RetrieveOrganizerPathParams) =>
      `/organizers/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  );
